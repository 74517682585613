import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'id',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'nickname',
    Object: 'value',
    label: '',
    width: '120'
  }, {
    prop: 'avatar',
    label: '',
    img: true,
    width: '120'
  }, {
    prop: 'phone_number',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'email',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'client_member',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'birthday',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'current_points',
    label: '',
    Object: 'value',
    width: '160'
  }, {
    prop: 'host',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'created_at',
    label: '',
    Object: 'value',
    width: '160'
  }],
  dialogDataItem: [{
    type: 'daterange',
    key: 'date',
    hintStart: i18n.t('placeholder.startDate'),
    hintEnd: i18n.t('placeholder.endDate'),
    name: i18n.t('form.members.dateRange')
  }]
}
